import { tryCatch } from '@thalesrc/js-utils';

import http from './http';
import {
  LOGIN_URL,
  CHECK_CONFIRMATION_C0DE,
  GET_CANDIDATE_APPLICANT,
  GENERATE_PASSWORD,
  FORGET_PASSWORD,
  GENERATE_PASSWORD_FORGET,
  CHANGE_PASSWORD,
  RECAPTCHA_URL
} from '../utils/url';

export async function loginUser(payload) {
  const url = `${LOGIN_URL}/${payload}`;
  const [error, result] = await tryCatch(http.post(url));
  if (error) throw error;

  return result.data.data;
}

export async function checkConfirmationCode(phoneNumber, smsCode) {
  const url = `${CHECK_CONFIRMATION_C0DE}/${phoneNumber}/${smsCode}`;

  const [error, result] = await tryCatch(http.post(url));
  if (error) throw error;

  return result.data.data;
}

export async function checkRecaptchaValidation(token) {
  // const rest = new Promise((resolve, reject) => {

  //   http.post(RECAPTCHA_URL, null, {
  //     headers: {
  //       'token': `${token}`,
  //     },
  //   })
  //   .then((response) => resolve(response.data))
  //   .catch((error) => {
  //     reject(error);
  //   });
  // });
  const url = `${RECAPTCHA_URL}?token=${token}`;

  const [error, result] = await tryCatch(http.post(url));

  if (error) throw error;
  return result.data;
}

export function getCandidateApplicant() {
  const rest = new Promise((resolve, reject) => {
    http
      .get(GET_CANDIDATE_APPLICANT)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });

  return rest;
}

export async function generatePassword(payload) {
  const url = GENERATE_PASSWORD;

  const result = await tryCatch(http.post(url, payload));

  if (result.errors) throw result.errors;
  // if (error) throw error;

  return result.data;
}

export async function forgetPassword(payload) {
  const url = FORGET_PASSWORD;

  const [error, result] = await tryCatch(http.post(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function changePasswordReset(payload) {
  const url = GENERATE_PASSWORD_FORGET;

  const [error, result] = await tryCatch(http.put(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function changePassword(payload) {
  const url = CHANGE_PASSWORD;

  const [error, result] = await tryCatch(http.put(url, payload));

  if (error) throw error;

  return result.data;
}
