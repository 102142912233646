import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { tryCatch } from '@thalesrc/js-utils';
import { useHistory } from 'react-router-dom';

import { LoadingContext } from './Loading';
import { ToasterContext } from './ToasterContext';
import { useStateStorage } from '../hooks/use-state-storage';
import { loginUser, checkConfirmationCode, getCandidateApplicant } from '../service/auth';
import { getCandidateStatus } from '../service/home';
import applicantStatu from '../utils/applicantStatu';
import pageURL from '../utils/pageUrls';

export const UserContext = createContext(null);

export function UserContextProvider({ children }) {
  const history = useHistory();
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { openToaster } = useContext(ToasterContext);
  const [token, setToken, removeToken] = useStateStorage('token');
  const [user, setUser] = useState('');
  const [language, setLanguage] = useStateStorage('language');

  useEffect(() => {
    if (history?.location?.pathname === '/health') return;
    if (localStorage.getItem('token')) {
      showLoading();
      getCandidateApplicant()
        .then((response) => {
          setUser(response.data);
          hideLoading();
        })
        .catch((error) => {
          removeToken('token');
          setUser(false);
          hideLoading();
          throw error;
        });
    } else {
      setUser(true);
    }
  }, [history, removeToken]);

  const login = useCallback(
    (payload) => {
      showLoading();
      return loginRequest(payload);
    },
    [hideLoading, history, setToken, showLoading]
  );

  const check = useCallback(
    (phoneNumber, smsCode) => {
      return checkConfirmationCodeRequest(phoneNumber, smsCode);
    },
    [setToken]
  );

  const logout = useCallback(() => {
    removeToken('token');
    history.push(pageURL.login);
  }, [history, removeToken]);

  const logout2 = useCallback(() => {
    removeToken('token');
  }, [removeToken]);

  const loginRequest = async (phoneNumber) => {
    const [err, res] = await tryCatch(loginUser(phoneNumber));
    hideLoading();
    if (err) return { IsSuccess: false, message: err.response.data.Errors[0] };
    return { IsSuccess: true, result: res?.data };
  };

  const checkConfirmationCodeRequest = async (phoneNumber, smsCode) => {
    const result = {};

    const [err, res] = await tryCatch(checkConfirmationCode(phoneNumber, smsCode));

    if (err) {
      openToaster('top-right', {
        severity: 'error',
        summary: 'Hatalı Giriş',
        detail: err.response.data.Errors[0],
        life: 3000
      });

      result.IsSuccess = false;
      // eslint-disable-next-line prefer-destructuring
      result.message = err.response.data.Errors[0];
    } else {
      setToken(res?.accessToken?.token);

      // Aday Evrak Paylaşıldı (2) ve Eksik Evrak Devam Ediyor (4) durumlarında sisteme giricek
      if (
        res.candidateApplicantDto.applicantStatusId === applicantStatu.documentShared ||
        res.candidateApplicantDto.applicantStatusId === applicantStatu.missingDocumentsContinue
      ) {
        setUser(res.candidateApplicantDto);
        history.push(pageURL.home);

        result.IsSuccess = true;
      } else {
        const [errorCanSt, resCanSt] = await tryCatch(getCandidateStatus());

        if (errorCanSt) {
          openToaster('top-right', {
            severity: 'error',
            summary: 'Hatalı Giriş',
            detail: errorCanSt.response.data.Errors[0],
            life: 3000
          });
        }
        logout2();

        result.IsSuccess = true;
        result.data = resCanSt ? resCanSt.data : '';
      }
    }

    return result;
  };

  const context = useMemo(
    () => ({
      user,
      token,
      login,
      logout2,
      check,
      logout,
      language,
      setLanguage
    }),
    [user, token, login, check, logout2, logout, language, setLanguage]
  );

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
}
