const BASE_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN_URL = `${BASE_URL}/auth/login`;
export const CHECK_CONFIRMATION_C0DE = `${BASE_URL}/auth/check-confirmation-code`;
export const RECAPTCHA_URL = `${BASE_URL}/auth/recaptcha-validation`;
export const GENERATE_PASSWORD = `${BASE_URL}/user/assigmentpassword`;
export const FORGET_PASSWORD = `${BASE_URL}/user/remindpassword`;
export const GET_CUSTOMER_URL = `${BASE_URL}/user`;
export const GENERATE_PASSWORD_FORGET = `${BASE_URL}/user/generate-password-for-reset`;
export const CHANGE_PASSWORD = `${BASE_URL}/user/passwordchange`;
export const VERSION_URL = `${BASE_URL}/version`;

// candidate
export const GET_CANDIDATE_APPLICANT = `${BASE_URL}/candidate-applicant`;
export const GET_CANDIDATE_DOCUMENT_LIST = `${BASE_URL}/candidate-document-list`;
export const GET_UPLOAD_DOCUMENT_SETTINGS = `${BASE_URL}/upload-document/settings`;
export const POST_UPLOAD_DOCUMENT = `${BASE_URL}/upload-document`;
export const POST_CANDIDATE_APPLICANT_PROCESS_DONE = `${BASE_URL}/candidate-applicant/process-done`;
export const GET_CANDIDATE_STATUS = `${BASE_URL}/candidate-applicant/status`;
