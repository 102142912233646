const applicantStatu = {
  created: 1, // Aday Oluşturuldu
  documentShared: 2, // Evrak Paylaşıldı
  hrApproval: 3, // IK Onayında
  missingDocumentsContinue: 4, // Eksik Evrak Devam Ediyor
  payrollApproval: 5, // Bordro Onayında
  done: 6, // Tamamlandı
  storeManagerApproved: 7 // Mağaza Müdürü Onayında
};

export default applicantStatu;
