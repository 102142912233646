const pageURL = {
  home: '/',
  login: '/login',
  forgetPassword: '/forget-password',
  generatePassword: '/remindpassword',
  changePasswordForget: '/change-password-forget',
  changePassword: '/change-password',
  health: '/health',

  sprintManagement: '/sprint-management',
  sprintManagementAction: '/sprint-management/:mode/:id?',

  // projectManagement: "/project-management",
  // projectManagementAction: "/project-management/:mode/:id?",

  staffManagement: '/staff-management',
  staffManagementAction: '/staff-management/:mode/:id?',

  customerManagement: '/customer-management',
  customerManagementAction: '/customer-management/:mode/:id?',

  teamManagement: '/team-management',
  teamManagementAction: '/team-management/:mode/:id?',

  authorityManagement: '/authority-management',

  roleManagement: '/role-management',
  roleManagementAction: '/role-management/:mode/:id?',

  systemParameters: '/system-parameters',
  systemParametersAction: '/system-parameters/:mode/:id?',

  excelUploadHistory: '/excel-upload-history',

  evaluationPeriods: '/evalution-periods',
  evaluationPeriodsAction: '/evalution-periods/:mode/:id?',

  checkListScore: '/check-list-score',
  checkListScoreAction: '/check-list-score/:mode/:id?',

  projectRatingManagement: '/project-rating-management',
  projectRatingManagementAction: '/project-rating-management/:mode/:id?',

  titleManagement: '/title-management',

  sprintReport: '/sprint-report',
  sprintReportDetail: '/sprint-report/:mode/:id',

  eventManagement: '/event-management',
  eventManagementAction: '/event-management/:mode/:id?',

  retroReport: '/retro-report',
  retroReportDetail: '/retro-report/:mode/:id?',

  companyManagement: '/company-management',
  companyManagementAction: '/company-management/:mode/:id?',

  agreementManagement: '/agreement-management',
  agreementManagementAction: '/agreement-management/:mode/:id?',

  targetListSetting: '/target-list-setting',
  targetListSettingAction: '/target-list-setting/:mode/:id?',

  targetAssignment: '/target-assignment',
  targetAssignmentAction: '/target-assignment/:mode/:id?',

  weeklyReview: '/weekly-review',
  weeklyReviewAction: '/weekly-review/:mode/:id?',

  monthlyAssessment: '/monthly-assessment',

  yearlyAssessment: '/yearly-assessment',
  businessResults: '/business-results',
  kpiResults: '/kpi-results',

  potentialManagement: '/potential',
  competenceManagement: '/competence',

  assignAreaAdmin: '/roles',

  reports: '/reports'
};

export default pageURL;
