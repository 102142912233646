import 'react-app-polyfill/ie11';
import '@thalesrc/js-utils/array/proto/uniquify';

import React from 'react';

import { locale, addLocale } from 'primereact/api';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import ScrollToTop from './template/ScrollToTop';
import { trLocale } from './utils/trLocale';

import './i18nextInit';

addLocale('tr', trLocale);
locale(localStorage.getItem('locale'));

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
