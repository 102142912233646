import React from 'react';

import PrimeReact from 'primereact/api';
import { Switch } from 'react-router';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './layout/layout.scss';
import './App.scss';
import './App.css';

// Context
import ConditionRoute from './components/ConditionRoute';
import Loader from './components/Loader';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { AppContextProvider } from './context/AppContext';
import { LoadingContextProvider } from './context/Loading';
import { ToasterContextProvider } from './context/ToasterContext';
import { UserContextProvider } from './context/UserContext';
import pageURL from './utils/pageUrls';

// Pages
const TheLayout = React.lazy(() => import('./template/TheLayout'));
const Login = React.lazy(() => import('./pages/Login'));
const ForgetPassword = React.lazy(() => import('./pages/ForgetPassword'));
const GeneratePassword = React.lazy(() => import('./pages/GeneratePassword'));
const ChangePasswordForget = React.lazy(() => import('./pages/ChangePasswordForget'));
const Health = React.lazy(() => import('./pages/Health'));

function App() {
  PrimeReact.ripple = true;

  return (
    <LoadingContextProvider>
      <AppContextProvider>
        <ToasterContextProvider>
          <UserContextProvider>
            <React.Suspense fallback={<Loader isLoaderShow />}>
              <Switch>
                <ConditionRoute component={Health} path={pageURL.health} exact />
                <PublicRoute component={Login} path={pageURL.login} exact />
                <PublicRoute component={ForgetPassword} path={pageURL.forgetPassword} exact />
                <PublicRoute component={GeneratePassword} path={pageURL.generatePassword} exact />
                <PublicRoute
                  component={ChangePasswordForget}
                  path={pageURL.changePasswordForget}
                  exact
                />
                <PrivateRoute component={TheLayout} path="*" exact />
              </Switch>
            </React.Suspense>
          </UserContextProvider>
        </ToasterContextProvider>
      </AppContextProvider>
    </LoadingContextProvider>
  );
}

export default App;
