import { tryCatch } from '@thalesrc/js-utils';

import http from './http';
import {
  GET_CANDIDATE_DOCUMENT_LIST,
  GET_UPLOAD_DOCUMENT_SETTINGS,
  POST_UPLOAD_DOCUMENT,
  POST_CANDIDATE_APPLICANT_PROCESS_DONE,
  GET_CANDIDATE_STATUS
} from '../utils/url';

export async function getCandidateDocumentList() {
  const [error, result] = await tryCatch(http.get(GET_CANDIDATE_DOCUMENT_LIST));
  if (error) throw error;

  return result.data.data;
}

export async function getDocumentSettings() {
  const [error, result] = await tryCatch(http.get(GET_UPLOAD_DOCUMENT_SETTINGS));
  if (error) throw error;

  return result.data;
}

export async function postDocument(documentListId, payload) {
  const url = `${POST_UPLOAD_DOCUMENT}/documentListId/${documentListId}`;

  const [error, result] = await tryCatch(
    http.post(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  );

  if (error) throw error;

  return result.data;
}
export async function getCandidateStatus() {
  const [error, result] = await tryCatch(http.get(GET_CANDIDATE_STATUS));

  if (error) throw error;

  return result.data;
}

export async function postProcessDone() {
  const [error, result] = await tryCatch(http.post(POST_CANDIDATE_APPLICANT_PROCESS_DONE));
  if (error) throw error;
  return result.data;
}
