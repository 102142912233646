import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import lngEn from './locales/en.json';
import lngTr from './locales/tr.json';

const langDetectorOptions = {
  order: ['localStorage', 'cookie', 'htmlTag', 'navigator'],

  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',

  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'],

  checkWhitelist: true
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      tr: { translation: lngTr },
      en: { translation: lngEn }
    },
    fallbackLng: 'tr',
    interpolation: { escapeValue: false },
    detection: langDetectorOptions
  });

export default i18n;
